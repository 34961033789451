//fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Days+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&display=swap");

// bulma imports
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";

// custom variables
@import "./styles/custom-variables-bulma.scss";

@import "~bulma";

@keyframes move-forever {
	0% {
		transform: translate3d(-90px, 0, 0);
	}
	100% {
		transform: translate3d(85px, 0, 0);
	}
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
	.waves {
		height: 4rem;
		min-height: 4rem;
	}
}

.animation-slow {
	animation-duration: 8s;
}

.is-family-header-title {
	font-family: $family-header-title;
}

html {
	scroll-behavior: smooth;
	overflow-x: hidden;
}

// ::selection {
// 	background: #009fff;
// 	color: #fff;
// }

body {
	overflow-x: hidden;
	position: relative;
	z-index: 1;
	background-color: $white;
}

.heading-group {
	display: flex;
	align-items: center;
	justify-content: center;

	.heading-container {
		position: relative;
	}

	.heading-decoration {
		position: absolute;
		top: -12px;
		left: -26px;
		width: 2rem;
		height: auto;

		@media screen and (min-width: $desktop) {
			width: 3.4rem;
			top: -22px;
			left: -44px;
		}
	}
}

.title.is-2 {
	@media screen and (min-width: $desktop) {
		font-size: 5rem;
	}
}

.title.is-4 {
	font-size: 1.7rem;
	@media screen and (min-width: $desktop) {
		font-size: 2.8rem;
	}
}

.header {
	min-height: calc(100vh - 3.2rem);
	overflow-y: hidden;
	overflow-x: hidden;
}

.header-content {
	margin-left: 2rem;

	@media screen and (max-width: $tablet) {
		margin-left: 0;
	}
}

.header-illustration {
	// transform: scale(1.1);
	margin-left: auto;
	margin-top: -30px;
	margin-right: -100px;

	@media screen and (max-width: $tablet) {
		margin-right: -50px;
	}
}

.header-blobs {
	width: 30%;
	min-width: 160px;
	position: absolute;
	top: -15%;
	left: -15%;
	z-index: -99;

	@media screen and (max-width: $tablet) {
		top: -4%;
		left: -4%;
	}
}

.header-arrow {
	width: 1.4rem;
	position: absolute;
	bottom: 8vh;
	left: 50%;
	transform: translateX(-50%);
	animation-duration: 8s;
}

.waves {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 17vh;
	margin-bottom: -7px; /*Fix for safari gap*/
	min-height: 7rem;
	max-height: 7rem;
	z-index: -99;
}

.parallax > use {
	animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
	animation-delay: -2s;
	animation-duration: 7s;
}
.parallax > use:nth-child(2) {
	animation-delay: -3s;
	animation-duration: 10s;
}
.parallax > use:nth-child(3) {
	animation-delay: -4s;
	animation-duration: 13s;
}
.parallax > use:nth-child(4) {
	animation-delay: -5s;
	animation-duration: 20s;
}

@media screen and (max-width: $desktop) {
	.navbar-menu {
		display: block;
		opacity: 0;

		position: absolute; /* or float: left; width: 100%;*/
		left: 0;
		right: 0;
		z-index: -1;

		transform: translateY(-50%);
		transition: all 0.3s ease-in-out;
		pointer-events: none;
	}

	.navbar-menu.is-active {
		opacity: 1;
		transform: none;
		pointer-events: auto;
	}
}

// SERVICES SECTION STYLES
.services-section {
	z-index: 1;
}

.service-section-blob {
	position: absolute;
	right: -4rem;
	top: 250px;
	z-index: -1;
	width: 8rem;
	height: 8rem;

	@media screen and (min-width: $tablet) {
		top: 400px;
		width: 8rem;
		height: 8rem;
		right: -2rem;
	}
}

.card {
	width: 15rem;
	margin: 0 auto;
	box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
}

.card-image {
	figure {
		width: 38%;
		margin: 0 auto 0 auto;
		padding-top: 1.2rem;
	}
}

@media screen and (min-width: $tablet) {
	.service-card-1 {
		transform: translateY(25px);
	}

	.service-card-2 {
		transform: translateY(60px);
	}

	.service-card-4 {
		transform: translateY(40px);
	}
}
// SERVICES SECTION STYLES END

// ROTATING BLOBS SECTION STYLES
.rotating-blobs-section {
	position: relative;
	padding-top: 0;
	padding-bottom: 0;

	@media screen and (min-width: $tablet) {
		margin-top: 5rem;
	}
}

.rotating-blobs-section-blob {
	position: absolute;
	bottom: -150px;
	left: -7rem;
	z-index: -99;
	height: 15rem;
	width: 15rem;
	// opacity: 0.5;
}

.rotating-blobs-container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 7.4rem;
	height: 7.4rem;
	margin: 1rem;

	@media screen and (min-width: $tablet) {
		width: 10rem;
		height: 10rem;
	}
}

.rotating-blobs {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(0);
		height: 100%;
		width: 100%;
	}

	.is-delay-1 {
		animation-delay: 2s;
	}

	.is-delay-2 {
		animation-delay: 3s;
	}

	.is-delay-3 {
		animation-delay: 4s;
	}

	.rotating-blob-1 {
		animation-duration: 18s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-name: rotateBlob;
	}
	.rotating-blob-2 {
		animation-duration: 19s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-name: rotateBlob;
	}
	.rotating-blob-3 {
		animation-duration: 20s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-name: rotateBlob;
	}
}

@keyframes rotateBlob {
	0% {
		transform: translate(-50%, -50%) rotate(0);
	}

	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
// ROTATING BLOBS SECTION STYLES END

// WHY CHOOSE US SECTION STYLES
.why-choose-us-section {
	margin-top: 2rem;
	@media screen and (min-width: $tablet) {
		margin-top: 3rem;
	}
}

.why-choose-us-illustration {
	// margin-left: 5rem;
	width: 75%;
	margin: 0 auto;

	@media screen and (min-width: $tablet) {
		width: 90%;
	}
}

.why-choose-us-blob {
	position: absolute;
	right: -6rem;
	bottom: 100px;
	z-index: -1;
	width: 10rem;
	height: 10rem;

	@media screen and (min-width: $tablet) {
		top: 450px;
		width: 10rem;
		height: 10rem;
		right: -4rem;
	}
}

.why-choose-us-section .list-item {
	position: relative;
	margin-left: 2.8rem;
	margin-right: 1rem;

	img {
		width: 20px;
		height: 20px;
		position: absolute;
		right: calc(100% + 10px);
		top: 0;
	}

	.title.is-5 {
		@media screen and (min-width: $tablet) {
			font-size: 1.5rem;
		}
	}

	.subtitle.is-6 {
		@media screen and (min-width: $tablet) {
			font-size: 1.1rem;
		}
	}
}

// WHY CHOOSE US SECTION STYLES END

// FILLER SECTION
.filler-section {
	margin-top: 8rem;

	.title.is-2 {
		font-size: 1.6rem;
		@media screen and (min-width: $tablet) {
			font-size: 2.6rem;
		}

		@media screen and (min-width: $desktop) {
			font-size: 3rem;
		}
	}

	.description {
		font-size: 1.1rem;

		@media screen and (min-width: $tablet) {
			font-size: 1.2rem;
		}

		@media screen and (min-width: $desktop) {
			font-size: 1.3rem;
		}
	}
}

.filler-blob {
	position: absolute;
	top: -15px;
	left: -15px;
	z-index: -1;
	width: 4rem;
	height: 4rem;

	@media screen and (min-width: $tablet) {
		top: -30px;
		left: -30px;
		width: 5rem;
		height: 5rem;
	}
}

.filler-group {
	position: relative;
	z-index: 1;
	padding-top: 10rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-bottom: 2.5rem;
	background: #6d3aae;
	border-radius: 1rem;

	@media screen and (min-width: $tablet) {
		padding: 3.8rem 13rem 3.8rem 4rem;
		width: 85%;
	}

	.illustration {
		position: absolute;
		top: 0%;
		left: 50%;
		transform: translate(-50%, -50%);
		animation-duration: 6s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		animation-name: upDown;

		@media screen and (min-width: $tablet) {
			left: 100%;
			top: 50%;
		}
	}

	.description {
		@media screen and (min-width: $tablet) {
			width: 80%;
		}
	}
}

@keyframes upDown {
	0% {
		transform: translate(-50%, -53%);
	}

	50% {
		transform: translate(-50%, -47%);
	}

	100% {
		transform: translate(-50%, -53%);
	}
}
// FILLER SECTION END

// ABOUT SECTION
.about-us-section {
	position: relative;
	z-index: 1;
	height: 100vh;
	min-height: 600px;
	margin-top: 3rem;

	.container {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.heading-group {
		margin-left: -7rem;

		@media screen and (min-width: 600px) {
			margin-top: -3rem;
		}
	}

	@media screen and (min-width: 450px) and (max-width: 599px) {
		height: 75vh;
		min-height: 400px;
	}

	@media screen and (min-width: 600px) and (max-width: 749px) {
		height: 100vh;
	}

	@media screen and (min-width: 750px) and (max-width: 899px) {
		height: 105vh;
	}

	@media screen and (min-width: 900px) {
		height: 110vh;
	}
}

.about-us-bg-blob {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200vw;
	height: 100%;
	min-height: 600px;
	transform: translate(-50%, -50%);
	z-index: -1;

	max-width: 200rem;
}

.about-us-description {
	width: 100%;
	padding-top: 1.4rem;
	margin: 0 auto;
	font-size: 1.1rem;

	@media screen and (min-width: 530px) and (max-width: 599px) {
		width: 90%;
	}

	@media screen and (min-width: 600px) and (max-width: 749px) {
		font-size: 1.2rem;
		width: 85%;
	}

	@media screen and (min-width: 750px) and (max-width: 859px) {
		font-size: 1.3rem;
		width: 75%;
	}

	@media screen and (min-width: 850px) and (max-width: 999px) {
		font-size: 1.3rem;
		width: 600px;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.4rem;
		width: 600px;
	}
}

.about-us-blob {
	position: absolute;
	right: -6rem;
	top: 50px;
	z-index: -1;
	width: 10rem;
	height: 10rem;

	@media screen and (min-width: $desktop) {
		top: 85vh;
		// bottom: 40px;
		width: 10rem;
		height: 10rem;
		left: 20vw;
	}
}

.about-us-illustration {
	position: absolute;
	top: 80%;
	left: 50%;
	transform: translateX(-30%);
	width: 20rem;

	@media screen and (min-width: 600px) {
		top: 75%;
		width: 25rem;
		transform: translateX(-20%);
	}

	@media screen and (min-width: 900px) {
		top: 73%;
		transform: translateX(0);
	}
}

// ABOUT SECTION END

// CONTACT FORM START

.contact-section {
	margin-top: 7rem;

	.heading-group {
		margin: 0 auto;
		margin-right: -1rem;
	}

	.input {
		font-weight: 600;
	}

	.textarea {
		font-weight: 600;
	}
}

.contact-blob {
	position: absolute;
	right: -4rem;
	bottom: 200px;
	z-index: -1;
	width: 8rem;
	height: 8rem;

	@media screen and (min-width: $tablet) {
		bottom: 200px;
		width: 10rem;
		height: 10rem;
		right: -4rem;
	}
}

.contact-bg {
	z-index: 1;

	max-width: 500px;
	background-color: $white;
	padding: 3rem 1.4rem 3rem;
	border-radius: 20px;
	box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
	margin: 0 auto;
}

// CONTACT FORM END

// FOOTER START

.footer-section {
	z-index: -99;
	margin-top: -13rem;
	background-color: $light-black;
	padding: 15rem 1rem 3rem;

	@media screen and (min-width: $tablet) {
		padding: 15rem 3rem 3rem;
	}
}

.footer-container {
	display: block;

	@media screen and (min-width: 1000px) {
		display: flex;
		justify-content: space-between;
	}

	.footer-services,
	.footer-contact {
		.title {
			margin-bottom: 0.8rem;
			font-size: 1.7rem;
		}
	}

	.footer-services {
		li {
			color: rgba(255, 255, 255, 0.6);
			&:hover {
				color: $secondary;
				cursor: pointer;
			}
		}
	}

	.footer-contact {
		a {
			color: rgba(255, 255, 255, 0.6);
			text-decoration: underline;
			&:hover {
				color: $secondary;
				cursor: pointer;
			}
		}
	}
}

.footer-section .footer-logo {
	img {
		width: 18rem;
	}
}

.footer-section .icons i {
	font-size: 2rem;
	margin-right: 1rem;
	transition: color 0.2s ease;

	&:hover {
		color: $secondary;
		cursor: pointer;
	}
}

.sub-footer {
	font-size: 0.9rem;
	@media screen and (min-width: $tablet) {
		// padding: 2rem 3rem;
		font-size: 1rem;
	}

	a {
		&:hover {
			color: $secondary;
		}
	}
}

// FOOTER END
